import { stripRichTextWrapperTag } from '@/utils/html';
import { inputCardAdapter, inputRadioAdapter } from '../hook-push-v2/adapter';

export default async ({ primary }, pageData, context) => {
  const { $prismic, $enhancedLinkSerializer } = context;
  const moduleId = primary?.hook_module_v2_simple?.id;

  if (!moduleId) {
    return;
  }

  const hookModuleV2 = await $prismic.client.getByID(moduleId);
  const hookModuleV2Data = hookModuleV2?.data;

  return {
    uiWrapperWidth: primary.ui_wrapper_width,
    form: {
      title: stripRichTextWrapperTag(hookModuleV2Data.form_title),
      ctaLabel: hookModuleV2Data.cta_label,
      cards: inputCardAdapter(
        hookModuleV2Data.body.find(
          ({ slice_type }) => slice_type === 'input_card'
        )
      ),
      radios: inputRadioAdapter(
        hookModuleV2Data.body.find(
          ({ slice_type }) => slice_type === 'input_radio'
        )
      ),
    },
    cta: $enhancedLinkSerializer(hookModuleV2Data?.cta_link),
    submitUrl: hookModuleV2Data?.cta_link?.url,
    isSimple: true,
    variant: 'transparent',
  };
};
